import React from 'react';

const IconPega = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1561 686"
    width="60px"
    height="60px"
    fill="none"
    stroke="currentColor"
    strokeWidth="60"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-pega">
    <title>Pega</title>
    <path d="m1557.4 371c0 0-15.7 28-22.7 38.4-7 10.4-29.1 16.9-48.1 7.8-8.7-4.1-90.3-51.6-121.5-48.9-157.9 13.7-201-51.3-206-57.2-5.1-5.9-14.6-3.2-7.8 8.5 34.6 59.3 137.1 86.9 137.1 86.9 26.9 64.5 30.1 109.8 30.1 109.8-26.6-20.7-55.3-38.1-85.7-52.6-81.3-54.5-208.5-122-383.7-164.3 10.1-138.9 80.2-339.6 408.1-292.2 0 0 65.7 10.8 109.6 41.1 0 0-10.5 11.1-24.4 24.9-13.8 13.8 1.5 22.6 1.5 22.6l69.2 54.4c0.8 28.8 2 36.9 23.7 62.9 21.7 26 105.9 114.2 116.9 126 12.8 13.6 3.7 31.9 3.7 31.9zm-164.5-197.2l-1.9-0.9c-0.2-0.1-22.7-10.2-40.2 8.2l-2.2 2.4 35.9 17.6zm-590.5-73.4c-17.4 71.3-16.1 134.2-7.1 187.1-16.5-3.3-33.2-6.4-50.4-9.2-358.8-58.4-467.1 118.4-425.2 261.7-118.6 44.5-230.1 95.5-319.6 145.6 140.9-337.5 446.9-634.6 705.2-673.6 84.3-11.2 108.1 43.5 97.1 88.4z" />
    <path d="m852.1 408.9c139.8-11.1 271.7 2.9 380.7 54.8-81.3-54.5-208.5-122-383.7-164.3-3.5 48.3 0.3 89.2 3 109.5zm-56.9-121.4c-16.4-3.3-33.1-6.4-50.3-9.2-358.8-58.3-467.1 118.4-425.2 261.7 170.4-63.9 355.6-114.4 512.2-129.4-12.7-31-28.1-72.5-36.7-123.1z" />
    <text x="200" y="950" fontSize="500">
      PEGA
    </text>
  </svg>
);

export default IconPega;
