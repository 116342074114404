import React from 'react';

const IconLocation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 512 512"
    fill="none"
    stroke="currentColor"
    strokeWidth="20"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-location"
    width="24px"
    height="24px">
    <title>Location</title>
    <g>
      <path d="M 236.5,-0.5 C 249.167,-0.5 261.833,-0.5 274.5,-0.5C 354.255,12.8164 403.755,58.8164 423,137.5C 430.494,180.205 423.827,220.539 403,258.5C 394.081,274.01 384.415,289.01 374,303.5C 355.5,327.333 337,351.167 318.5,375C 336.805,378.41 354.805,383.076 372.5,389C 390.241,394.948 405.408,404.782 418,418.5C 428,433.833 428,449.167 418,464.5C 406.63,476.771 393.13,485.938 377.5,492C 347.276,502.81 316.276,509.31 284.5,511.5C 265.167,511.5 245.833,511.5 226.5,511.5C 192.083,509.314 158.749,501.814 126.5,489C 113.648,483.155 102.481,474.989 93,464.5C 83,449.167 83,433.833 93,418.5C 110.86,400.315 132.36,388.481 157.5,383C 169.162,380.202 180.828,377.535 192.5,375C 174,351.167 155.5,327.333 137,303.5C 122.908,284.331 110.575,263.998 100,242.5C 85.5695,208.72 81.5695,173.72 88,137.5C 101.868,75.3043 138.368,32.471 197.5,9C 210.363,4.70016 223.363,1.5335 236.5,-0.5 Z M 254.5,19.5 C 317.895,22.4529 363.729,52.1195 392,108.5C 412.406,160.295 408.073,209.961 379,257.5C 371.915,268.672 364.581,279.672 357,290.5C 322.944,334.381 289.111,378.381 255.5,422.5C 224.938,381.762 193.771,341.429 162,301.5C 150.591,286.353 139.925,270.686 130,254.5C 104.322,211.47 99.3216,166.136 115,118.5C 141.102,56.8572 187.602,23.8572 254.5,19.5 Z M 245.5,120.5 C 275.604,117.794 295.104,130.794 304,159.5C 306.352,194.811 290.019,214.811 255,219.5C 220.49,214.323 204.49,194.323 207,159.5C 212.683,139.318 225.516,126.318 245.5,120.5 Z M 206.5,394.5 C 220.264,410.646 233.098,427.646 245,445.5C 252,453.5 259,453.5 266,445.5C 277.936,427.964 290.603,410.964 304,394.5C 332.214,397.595 359.048,405.429 384.5,418C 392.618,422.451 399.118,428.617 404,436.5C 405.041,441.44 404.374,446.106 402,450.5C 393.824,459.675 383.991,466.508 372.5,471C 334.742,484.738 295.742,491.571 255.5,491.5C 215.258,491.571 176.258,484.738 138.5,471C 95.8333,451.333 95.8333,431.667 138.5,412C 160.565,403.318 183.231,397.485 206.5,394.5 Z" />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        d="M 251.5,39.5 C 262.988,38.1542 267.822,43.1542 266,54.5C 262.301,59.272 257.467,60.772 251.5,59C 243.683,52.479 243.683,45.979 251.5,39.5 Z"
      />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        d="M 291.5,46.5 C 295.605,46.2338 299.605,46.7338 303.5,48C 350.177,68.0197 377.344,103.186 385,153.5C 385.667,160.833 385.667,168.167 385,175.5C 378.667,182.167 372.333,182.167 366,175.5C 365.702,137.208 350.869,106.041 321.5,82C 310.423,74.752 298.923,68.252 287,62.5C 283.994,55.7714 285.494,50.4381 291.5,46.5 Z"
      />
    </g>
    <g>
      <path d="M 243.5,99.5 C 280.726,96.271 306.893,111.604 322,145.5C 331.793,179.116 322.96,206.616 295.5,228C 273.509,240.988 250.509,242.988 226.5,234C 193.678,216.52 180.512,189.354 187,152.5C 195.741,123.922 214.574,106.255 243.5,99.5 Z M 245.5,120.5 C 225.516,126.318 212.683,139.318 207,159.5C 204.49,194.323 220.49,214.323 255,219.5C 290.019,214.811 306.352,194.811 304,159.5C 295.104,130.794 275.604,117.794 245.5,120.5 Z" />
    </g>
  </svg>
);

export default IconLocation;
