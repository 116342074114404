import React from 'react';

const IconLink = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 100 100"
    width="20px"
    height="20px"
    fill="none"
    stroke="currentColor"
    strokeWidth="6"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-link">
    <title>Link</title>
    <path
      d="M0 0 C1.03640625 0.01546875 2.0728125 0.0309375 3.140625 0.046875 C5.75 0.25 5.75 0.25 7.75 1.25 C7.0703125 3.23046875 7.0703125 3.23046875 5.75 5.25 C3.4296875 5.61328125 3.4296875 5.61328125 0.625 5.5625 C-6.02440303 5.94395206 -8.82510908 8.5373113 -13.25 13.25 C-14.72902105 14.68764983 -16.20818563 16.125152 -17.6875 17.5625 C-21.21759421 21.0787778 -22.4273229 22.98204281 -22.625 28 C-22.6033442 31.74103938 -22.27882688 33.9066975 -19.875 36.8125 C-15.91627269 40.48846107 -13.1932974 40.77952066 -7.90625 40.71875 C-3.09851702 39.87032653 -0.48735314 36.69413019 2.75 33.25 C4.22898496 31.81231305 5.70814838 30.37480966 7.1875 28.9375 C11.87569519 24.26765391 11.92737205 21.29128414 12.17578125 14.78125 C12.75 12.25 12.75 12.25 14.79296875 10.90625 C15.43878906 10.6896875 16.08460937 10.473125 16.75 10.25 C18.07084611 12.89169222 17.99214193 14.98616431 18.0625 17.9375 C18.11857422 19.45923828 18.11857422 19.45923828 18.17578125 21.01171875 C17.25210315 28.03675681 12.74890254 32.73658895 7.875 37.5 C7.37097656 38.015625 6.86695313 38.53125 6.34765625 39.0625 C1.4588391 43.91380705 -3.11140239 46.67820158 -10.1875 46.6875 C-15.78509263 46.53490144 -18.82274621 45.6626748 -23.25 42.25 C-27.36639878 37.80862237 -28.41835677 34.4781509 -28.5625 28.5 C-28.59988281 27.49453125 -28.63726563 26.4890625 -28.67578125 25.453125 C-27.74500793 18.45097709 -23.23415795 13.74900071 -18.375 9 C-17.87097656 8.484375 -17.36695313 7.96875 -16.84765625 7.4375 C-11.80251583 2.4310691 -7.30000315 -0.18341716 0 0 Z "
      transform="translate(36.25,45.75)"
    />
    <path
      d="M0 0 C4.85503811 2.57956668 8.08027514 6.08848167 9.80859375 11.2734375 C9.9791001 13.18534477 10.07482495 15.10450003 10.12109375 17.0234375 C10.17716797 18.53164063 10.17716797 18.53164063 10.234375 20.0703125 C9.30360168 27.07246041 4.7927517 31.77443679 -0.06640625 36.5234375 C-0.57042969 37.0390625 -1.07445312 37.5546875 -1.59375 38.0859375 C-5.65583004 42.11685068 -9.45797956 44.98785561 -15.33203125 45.6015625 C-16.87117188 45.56289062 -16.87117188 45.56289062 -18.44140625 45.5234375 C-19.4778125 45.50796875 -20.51421875 45.4925 -21.58203125 45.4765625 C-24.19140625 45.2734375 -24.19140625 45.2734375 -26.19140625 44.2734375 C-25.51171875 42.29296875 -25.51171875 42.29296875 -24.19140625 40.2734375 C-21.87109375 39.91015625 -21.87109375 39.91015625 -19.06640625 39.9609375 C-12.41700322 39.57948544 -9.61629717 36.9861262 -5.19140625 32.2734375 C-3.7123852 30.83578767 -2.23322062 29.3982855 -0.75390625 27.9609375 C2.77618796 24.4446597 3.98591665 22.54139469 4.18359375 17.5234375 C4.16193795 13.78239812 3.83742063 11.61674 1.43359375 8.7109375 C-2.52513356 5.03497643 -5.24810885 4.74391684 -10.53515625 4.8046875 C-15.34288923 5.65311097 -17.95405311 8.82930731 -21.19140625 12.2734375 C-22.67039121 13.71112445 -24.14955463 15.14862784 -25.62890625 16.5859375 C-30.31710144 21.25578359 -30.3687783 24.23215336 -30.6171875 30.7421875 C-31.19140625 33.2734375 -31.19140625 33.2734375 -33.234375 34.6171875 C-33.88019531 34.83375 -34.52601562 35.0503125 -35.19140625 35.2734375 C-36.51225236 32.63174528 -36.43354818 30.53727319 -36.50390625 27.5859375 C-36.54128906 26.57144531 -36.57867187 25.55695312 -36.6171875 24.51171875 C-35.6935094 17.48668069 -31.19030879 12.78684855 -26.31640625 8.0234375 C-25.81238281 7.5078125 -25.30835938 6.9921875 -24.7890625 6.4609375 C-17.87173314 -0.40331772 -9.5832558 -3.17733615 0 0 Z "
      transform="translate(82.19140625,8.7265625)"
    />
    <path
      d="M0 0 C-0.42034784 4.81246485 -2.68031592 7.26674729 -5.98828125 10.51953125 C-6.48788864 11.02296555 -6.98749603 11.52639984 -7.50224304 12.04508972 C-8.55737413 13.10293479 -9.61754168 14.15577729 -10.68237305 15.20385742 C-12.3107521 16.81077514 -13.91664372 18.43797098 -15.52148438 20.06835938 C-16.55080286 21.09503537 -17.58136108 22.12047026 -18.61328125 23.14453125 C-19.09259598 23.6319429 -19.57191071 24.11935455 -20.06575012 24.62153625 C-23.02619782 27.50029573 -23.65990801 28 -28 28 C-27.57965216 23.18753515 -25.31968408 20.73325271 -22.01171875 17.48046875 C-21.51211136 16.97703445 -21.01250397 16.47360016 -20.49775696 15.95491028 C-19.44262587 14.89706521 -18.38245832 13.84422271 -17.31762695 12.79614258 C-15.6892479 11.18922486 -14.08335628 9.56202902 -12.47851562 7.93164062 C-11.44919714 6.90496463 -10.41863892 5.87952974 -9.38671875 4.85546875 C-8.90740402 4.3680571 -8.42808929 3.88064545 -7.93424988 3.37846375 C-4.97380218 0.49970427 -4.34009199 0 0 0 Z "
      transform="translate(64,36)"
    />
  </svg>
);

export default IconLink;
